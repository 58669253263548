import React, {useRef, useEffect, useState} from 'react';
import {useDatabase} from "../Hooks/useDatabase";
import Container from "react-bootstrap/Container";
import {DisplayAccountPost} from "./Posts";
import {useUid} from "../Hooks/useUid";
import Image from "react-bootstrap/Image"
import Form from "react-bootstrap/Form";
export default function ViewPosts(props) {
    console.log(props)
    const {posts, loading, error} = useDatabase(props.props.match.params.uid)
    const {url, loading1, error1} = useUid(props.props.match.params.uid);
    const [currPosts, setCurr] = useState([]);

    const [filter, setFilter] = useState("");
    useEffect(() => {
        setCurr(() => posts.filter((val) => val.Title.toLowerCase().includes(filter)))
        console.log('here')
    }, [filter])
    useEffect(() => {
        setCurr(() => posts)
    }, [posts])
    if(loading){
        return <span className="text-center">loading</span>
    }
    function Follow() {

    }
    console.log(filter);
    return(<Container>
        <div>
        <h1>{url.DisplayName}</h1>
        <Image src={url.Photo} width="200" height="200" roundedCircle={true}/>
        <Form.Control type="text" onChange={(e) => setFilter(e.target.value)}/>
        </div>
        {currPosts.map((post, i) => <DisplayAccountPost  key={i} uid={props.props.match.params.uid} history={props.props.history} post={post} player={props.player} setCurrPost={props.setCurrPost} setPlaylist={props.setPlaylist}/>)}

    </Container>)
}

