import React, {useContext, useRef} from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form'
import {Link} from 'react-router-dom';
import * as ROUTES from '../Constants/Routes';
import {fbContext} from '../Firebase';
import {useLocation} from 'react-router';


export default function Navigation({user, history, upload}) {
    const fb = useContext(fbContext)
    const searchValue= useRef();
    const signIn = async () => {
        await fb.auth.signInWithPopup(fb.provider)
    }
    const onSearch = (e) => {
        e.preventDefault();
        history.push(`${ROUTES.SEARCH}/${searchValue.current.value}`)
    }
    const signOut = () => {
        fb.auth.signOut().then(() => {
            alert('Signed Out')
        }).then(() => history.push(ROUTES.LANDING))
    }
    const safeR = async (e) => {
        if(await window.confirm("Leave While Uploading")){
            history.push(e.target.value)
        }
    }
    if(upload){
        return(
        <Navbar bg="light" expand="lg">
            <Navbar.Brand onClick={(e) => safeR(e)} value={ROUTES.LANDING}>Berzerk-Music</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link onClick={(e) => safeR(e)} value={ROUTES.LANDING}>Home</Nav.Link>
                </Nav>
                <Form inline>
                    <FormControl type="text" placeholder="Search" className="mr-sm-2"/>
                    <Button variant="outline-success">Search</Button>
                </Form>
            </Navbar.Collapse>
            {user ? <Button onClick={() => signOut()}>Sign Out</Button> :
                <Button onClick={() => signIn()}>Sign In</Button>}
        </Navbar>)
    }
    if(!user) {
        return(<Navbar bg="light" expand="lg">
            <Navbar.Brand as={Link} to={ROUTES.LANDING}>Berzerk-Music</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link as={Link} to={ROUTES.LANDING}>Home</Nav.Link>
                </Nav>
                <Form inline>
                    <FormControl type="text" placeholder="Search" className="mr-sm-2"/>
                    <Button variant="outline-success">Search</Button>
                </Form>
            </Navbar.Collapse>
            {user ? <Button onClick={() => signOut()}>Sign Out</Button> :
                <Button onClick={() => signIn()}>Sign In</Button>}
        </Navbar>)
    }else{
        return (<Navbar bg="light" expand="lg">
            <Navbar.Brand as={Link} to={ROUTES.LANDING}>Berzerk-Music</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link as={Link} to={ROUTES.LANDING}>Home</Nav.Link>
                    <Nav.Link as={Link} to={ROUTES.UPLOAD}>Upload</Nav.Link>
                    <Nav.Link as={Link} to={ROUTES.POSTS}>Posts</Nav.Link>
                </Nav>
                <Form inline onSubmit={(e) => onSearch(e)}>
                    <FormControl type="text" placeholder="Search" className="mr-sm-2" ref={searchValue}/>
                    <Button type='submit'>Search</Button>
                </Form>
            </Navbar.Collapse>
            {user ? <Button onClick={() => signOut()}>Sign Out</Button> :
                <Button onClick={() => signIn()}>Sign In</Button>}
        </Navbar>)
    }
}
