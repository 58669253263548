import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useContext, useState, useEffect} from 'react'
import './App.css';
import {Switch, Route, useHistory} from "react-router-dom";
import * as ROUTES from './Constants/Routes';
import Landing from './Components/Landing';
import Navigation from "./Components/Navigation";
import Container from 'react-bootstrap/Container';
import Upload from './Components/Upload';
import Posts from './Components/Posts';
import Search from "./Components/Search";
import InspectPost from "./Components/InspectPost";
import {fbContext} from "./Firebase";
import Player from "./Components/Player";
import Button from "react-bootstrap/Button";
import ViewPosts from "./Components/ViewPosts";
import {PlayerContext} from "./PlayerContext";

function App() {
    const fb = useContext(fbContext)
    const playerc = useContext(PlayerContext);
    const [user, setUser] = useState(window.localStorage.getItem('user') || null)
    const [currPost, setCurrPost] = useState({})
    const [playlist, setPlaylist] = useState([]);
    const [player, setPlayer] = useState(false)
    const [upload, setUpload] = useState(false);
    const history = useHistory()

    fb.auth.onAuthStateChanged((user) =>{
        if (user) {
            setUser(user)
            fb.database.ref(user.uid).update({Photo: user.photoURL, Username: user.displayName});
            window.localStorage.setItem('user', user);
        } else {
            window.localStorage.removeItem('user');
            setUser(null)

        }
    })
    let validator = {
        set: function(target, key, value) {
            setCurrPost(value)
            target.currPost = value;
            return true;
        },
        get: function(target, property, receiver) {
            return target
        }

    };
    let store = new Proxy(playerc, validator);

    useEffect(() => {
        console.log('effect')
        if(playerc.currPost !== null){
            console.log('here')
            setPlayer(true)
            setCurrPost(playerc.currPost);

            console.log(player)
        }
    }, [playerc.currPost])
    console.log(playerc)
    return (
    <Container className="App">
        <Navigation user={user} history={history} upload={upload}/>
        <Switch>
            <Route exact path={ROUTES.LANDING} component={() => Landing({ history, setCurrPost, player, setPlaylist, store})}/>
            <Route path={`${ROUTES.SEARCH}/:id`} component={Search}/>
            <Route path={`${ROUTES.POST}/:id`} component={(props) => InspectPost({props})}/>
            <Route path={`${ROUTES.POSTS}/:uid`} component={(props) => ViewPosts({props, player, setCurrPost, setPlaylist})}/>
            {user && <Route exact path={ROUTES.UPLOAD} component={() => Upload({user, setUpload})}/>}
            {user && <Route exact path={ROUTES.POSTS} component={() => Posts({user, history, fb})}/>}
        </Switch>
        {player && <Player file={currPost}/>}

    </Container>
  );
}

export default App;
