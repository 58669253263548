import React, {useContext, useState, useEffect} from 'react'
import {fbContext} from "../Firebase"
import {useMountedRef} from "./useMountedRef";

export function useAudio(id){
    const mounted = useMountedRef()
    const fb = useContext(fbContext)
    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()


    useEffect(() => {
        if(!id)return;
        if(!mounted.current)return;
        fb.database.ref(`audio`).limitToFirst(100).once('value').then(snapshot => {if(!mounted.current) throw new Error("Component Not Mounted"); return snapshot;})
            .then((snapshot) => snapshot.forEach(snapshot=> {
                if(snapshot.val().Title.toLowerCase().includes(id)) {
                    setPosts((posts) => [...posts, snapshot.val()])
                }else if(snapshot.val().Tag.toLowerCase().includes(id)){
                    setPosts((posts) => [...posts, snapshot.val()])
                }else if(snapshot.val().Genre.toLowerCase().includes(id)){
                    setPosts((posts) => [...posts, snapshot.val()])
                }else if(id==="all"){
                    setPosts((posts) => [...posts, snapshot.val()])
                }
            }))
            .then(() => setLoading(false))
            .catch((error) => {if(!mounted.current) return; setError(error)})
    }, [id])
    return {posts, loading, error}

}
