import React, {useState, useRef, useContext, useEffect} from 'react';
import {useDatabase} from '../Hooks/useDatabase';
import Card from 'react-bootstrap/Card';
import Player from './Player';
import Button from 'react-bootstrap/Button';
import * as ROUTES from '../Constants/Routes';
import AccountControls from "./AccountControls";
import Form from "react-bootstrap/Form";
import {fbContext} from "../Firebase";
import Container from "react-bootstrap/Container";
import {useUid} from "../Hooks/useUid";
import Image from "react-bootstrap/Image";
import PlayImage from "../Res/play-button.jpg";
import {PlayerContext} from "../PlayerContext";
export default function Posts({user, history, fb}){
    if(!user){
        history.push(ROUTES.LANDING)
    }

    const {posts,loading,error} = useDatabase(user.uid);
    const [player, setPlayer] = useState(false);

    const AudioHolder = ({post}) => {
        return(<Card className='p-3'>
            <Card.Title>{post.Title}</Card.Title>
            <Card.Body>
                <Card.Text>
                    {post.Description}
                </Card.Text>
                <Button onClick={() => setPlayer(!player)}>Show Player</Button>
                {player && <Player post={post}/>}
            </Card.Body>
            <AccountControls post={post} user={user} fb={fb}/>
            <Card.Footer>
                <Card.Text>
                    Tag: {post.Tag}
                </Card.Text>
                <Card.Text>
                    Genre: {post.Genre}
                </Card.Text>
            </Card.Footer>

        </Card>)

    }

    if(loading){
        <span className="text-center">Loading</span>
    }
    return(<div>
        {posts.map((post, i) => <AudioHolder key={i} post={post}/>)}

    </div>)
}


export const DisplayAccountPost = ({post,uid, history, setCurrPost = f => f, player, setPlaylist = f => f}) =>{

    const [playing, setPlaying] = useState(false)
    const onInspect = (post) => {
        history.push(`${ROUTES.POST}/${post.Key}`);
    }
    const onViewPosts = (post) => {
        history.push(`${ROUTES.POSTS}/${post.Uid}`);
    }
    useEffect(() => {
        if(playing){

        }
    }, [playing])
    function play(e){
        e.preventDefault();
        setCurrPost(post)
        setPlaying(true)

    }
    return(<Card className='p-3'>
        <Card.Header>
            <h4>{post.Title}</h4>
            {player && <Button onClick={() => setPlaylist((prev) => [...prev, post])}>Add to PlayList</Button>}
            <div style={{position: "relative", left: "0", top: "0"}} className="m-auto">
                <Image src={post.IUrl} className="image" width="175" height="175" style={{display: "block", left: "0", top: "0"}}/>
                {playing && <Image src={PlayImage} className="image" width="175" height="175" style={{position: "absolute", left:"0px", top: "0px", opacity: "0.25"}}/>}
            </div>
            <Button onClick={(e) => play(e)}>Play</Button>
        </Card.Header>

    </Card>)

}

export const DisplayPost = ({post, history, setCurrPost = f => f, player, setPlaylist = f => f, store}) =>{
    const {url, loading, error} = useUid(post.Uid);
    const [playing, setPlaying] = useState(false)
    const playerc = useContext(PlayerContext);

    const onInspect = (post) => {
        history.push(`${ROUTES.POST}/${post.Key}`);
    }
    const onViewPosts = (post) => {
        history.push(`${ROUTES.POSTS}/${post.Uid}`);
    }
    useEffect(() => {
      if(playing){

      }
    }, [playing])
    function play(e){
        e.preventDefault();
        store.currPost = post;
        console.log(store.currPost)

    }
    return(<Card className='p-3'>
        <Card.Header>
            <div className="justify-content-center">
                <h1 className="float-left m-auto text-center">{url.DisplayName}</h1>
                <Image src={url.Photo} width="100" height="100" className="float-right" roundedCircle="true" onClick={() => history.push(`${ROUTES.POSTS}/${post.Uid}`) }/>
            </div>
            <h4>{post.Title}</h4>
            {player && <Button onClick={() => setPlaylist((prev) => [...prev, post])}>Add to PlayList</Button>}
            <div style={{position: "relative", left: "0", top: "0"}} className="m-auto">
            <Image src={post.IUrl} className="image" width="175" height="175" onClick={(e) => play(e)} style={{display: "block", left: "0", top: "0"}}/>
                {playing && <Image src={PlayImage} className="image" width="175" height="175" style={{position: "absolute", left:"0px", top: "0px", opacity: "0.25"}}/>}
            </div>

        </Card.Header>
        <Card.Footer>
            <h5 className="text-center">{post.Genre}</h5>
            <h5 className="text-center  ">{post.Tag}</h5>
        </Card.Footer>

    </Card>)

}

export const DisplayInspectPost = ({post, currentPlayer, setPlayer = f => f, setPost = f => f}) =>{
    const fb = useContext(fbContext)


    const content = useRef()
    const onSubmit = () => {
        fb.database.ref(`audio/${post.Key}/Comments`).push({
            Content: content.current.value,

        }).then(() => alert('Posted Comment'))
    }
    const onPlay = () => {
        setPost(post)
        setPlayer(!currentPlayer)
    }
    return(<Card className='p-3'>
        <Card.Title>{post.Title}</Card.Title>
        <Card.Body>
            <Card.Text>
                {post.Description}
            </Card.Text>
            <Button onClick={() => onPlay()}>Show Player</Button>
        </Card.Body>
        <Card.Footer>
            <Card.Text>
                Tag: {post.Tag}
            </Card.Text>
            <Card.Text>
                Genre: {post.Genre}
            </Card.Text>
        </Card.Footer>
        <Form onSubmit={(e) => onSubmit(e)}>
            <Form.Group>
                <Form.Label>Comment</Form.Label>
                <Form.Control type='text' ref={content}/>
            </Form.Group>
            <Button type='submit'>Post</Button>
        </Form>
        {post.Comments && <Container>{Object.keys(post.Comments).map((comment) => <Card className='text-center' key={comment}>`{post.Comments[comment].Content}</Card>)}</Container>}

    </Card>)

}
