import {useDatabase} from "../Hooks/useDatabase";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
export default function AccountControls({user, post, fb}){
    const onDelete = () => {
        const ref = fb.storage.ref(user.uid).child(post.Title)
        const dref = fb.database.ref(user.uid);
        dref.child("audio/" + post.Key).remove()
        ref.delete().then(() => {
            alert("Deleted");
        })
    }


    return(<Container>
        <Button onClick={() => onDelete()}>Delete</Button>

    </Container>)
}
