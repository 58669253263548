import React, {useEffect, useState} from 'react';
import {useAudio} from "../Hooks/useAudio";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {DisplayPost} from "./Posts";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import CardDeck from "react-bootstrap/CardDeck";
import CardColumns from "react-bootstrap/CardColumns"
import {BsChevronRight } from "react-icons/bs";
import {BsChevronLeft } from "react-icons/bs";

export default function Landing({history, setCurrPost, store}){
    const {posts, loading,error} = useAudio("all")
    if(loading){
        return <span className="text-center">loading</span>
    }


    return (<Container>
        <Row>
            <h2 className="text-center">Music</h2>
            <FancyView posts={posts} history={history} setCurrPost={setCurrPost} store={store}/>

        </Row>

    </Container>)
}

const FancyView = ({posts, history, setCurrPost, player, setPlaylist, store}) => {
    const [startIndex, setStartIndex] = useState( 0);
    const [endIndex, setEndIndex] = useState(   3);

    const incre = () => {
        if(endIndex < posts.length){
            setEndIndex((prev) => prev + 1)
            setStartIndex((prev) => prev + 1)


        }
    }
    const decre = () => {
        if(startIndex > 0){
            setEndIndex((prev) => prev - 1)
            setStartIndex((prev) =>     prev - 1)
        }
    }
    return(<Container>
        <CardDeck className='p-2 justify-content-center'>
            <Button variant='dark' className="" onClick={() => decre()}><BsChevronLeft/></Button>
            <Row>
                {posts.slice(startIndex, endIndex).map((post, i) => <Col key={i}><DisplayPost key={i} history={history} post={post} player={player} setCurrPost={setCurrPost} setPlaylist={setPlaylist} store={store}/></Col>)}
            </Row>
            <Button variant='dark' className="" onClick={() => incre()}><BsChevronRight/></Button>
        </CardDeck>
    </Container>)

}
