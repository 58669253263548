import React,{useRef, useState, useContext, useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {fbContext} from "../Firebase";
import Image from "react-bootstrap/Image";
export default function Upload({user, setUpload = f => f}) {
    const fb = useContext(fbContext)
    const title = useRef('')
    const description = useRef('')
    const [genre, setGenre] = useState('Rap')
    const [tag, setTag] = useState('Chill')
    const [file, setFile] = useState()
    const [iFile, setIFile] = useState([])
    const [progress,setProgress] = useState(0)
    const [privateU, setPrivate] = useState(false);
    const setIURL = (i) => setIFile([i,URL.createObjectURL(i)])
    useEffect(() => {
        if(privateU){
            alert("Private Videos are only viewable by you and whoever you share the sharable link too")
        }
    }, [privateU])
    window.addEventListener("beforeunload", function(event) {
        console.log('here')
        if (!(window.confirm("DO YOU really want to exit a fun page like this?"))) {
            event.preventDefault();
        }
    });
    async function uploadTask(task){
        return new Promise(function (resolve, reject){
            task.on('state_changed',
                function progress(snapshot) {
                    setProgress(() => (snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                },
                function error(err) {
                    reject(err);
                },
                function complete() {
                    task.snapshot.ref.getDownloadURL().then((url) => {
                        resolve(url)

                    })

                })
        })
    }
    const onSubmit = (e) => {
        e.preventDefault();
        const task = fb.storage.ref(`${user.uid}/${title.current.value}`).put(file)
        const task2 = fb.storage.ref(`${user.uid}/${title.current.value}Image`).put(iFile[0])
        const tasks = [uploadTask(task), uploadTask(task2)]
        Promise.all(tasks).then((urls)=>{
            console.log(urls)
            if(privateU) {
                const uref = fb.database.ref(`${user.uid}/audio`).push()
                const item = {
                    Title: title.current.value,
                    Description: description.current.value,
                    Genre: genre,
                    Tag: tag,
                    Url: urls[0],
                    IUrl: urls[1],
                    Key: uref.key,
                    Private: "True"}
                uref.set(item).then(() => console.log('Set U'));
            }else{
                const lref = fb.database.ref('audio').push()
                const uref = fb.database.ref(`${user.uid}/audio`).push()
                const item = {
                    Title: title.current.value,
                    Description: description.current.value,
                    Genre: genre,
                    Tag: tag,
                    Url: urls[0],
                    IUrl: urls[1],
                    Key: uref.key}
                uref.set(item).then(() => console.log('Set U'));
                const iteml = {
                    Title: title.current.value,
                    Description: description.current.value,
                    Genre: genre,
                    Tag: tag,
                    Url: urls[0],
                    IUrl: urls[1],
                    Key: lref.key,
                    Uid: user.uid
                };
                lref.set(iteml).then(() => console.log('Set L'));
            }
        }).then(() => alert("Uploaded"))

    }

    return(<Form onSubmit={(e) =>  onSubmit(e)}>
        <h1>Upload</h1>
        <Form.Group>
            <Form.Label>Title</Form.Label>
            <Form.Control type='text' ref={title} required/>
        </Form.Group>
        <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control as='textarea' rows={7} ref={description} required/>
        </Form.Group>
        <Form.Group>
            <Form.Label className='file-upload'><Form.File className='form-file' accept='image/*' onChange={(e) => setIURL(e.target.files[0])} required/>
                <div className="btn-outline-dark">Upload Cover</div>{iFile.length === 2 && <Image src={iFile[1]} width="175rem" height="175rem" className="btn-outline-primary"/>}</Form.Label>
        </Form.Group>
        <Row>
            <Col>
        <Form.Group>
            <Form.Label className='file-upload'><Form.File className='form-file' accept='audio/*' onChange={(e) => setFile(e.target.files[0])} required/>
                <div className="btn-outline-dark">Upload Audio</div>{file && <span> {file.name}</span>}</Form.Label>
        </Form.Group>

            </Col>
        </Row>
        <Row>
            <Col>
        <Form.Group>
            <Form.Label>Genre</Form.Label>
            <Form.Control as='select' onChange={(e) => setGenre(e.target.value)} required>
                <option>Rap</option>
                <option>Instrumental</option>
            </Form.Control>
        </Form.Group>
            </Col>
            <Col>
        <Form.Group>
            <Form.Label>Tag</Form.Label>
            <Form.Control as='select' onChange={(e) => setTag(e.target.value)} required>
                <option>Chill</option>
                <option>Dark</option>
                <option>Trap</option>
                <option>Boom-Bap</option>
                <option>Drill</option>
                <option>Dreamy</option>
                <option>Reverse</option>
            </Form.Control>
        </Form.Group>
            </Col>
        </Row>
        <Form.Group>
            <Form.Check label="Private" onChange={() => setPrivate((prev) => !prev)}/>
        </Form.Group>
        <Button type='submit' variant='success'>Submit</Button>
        <ProgressBar now={progress}/>
    </Form>)
}
