
import React,{useRef, useEffect} from "react";
export function useMountedRef() {
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false)
    })
    return mounted;
}
