import React from 'react';
import Container from 'react-bootstrap/Container';
import {useAudio} from "../Hooks/useAudio";
import {DisplayPost} from "./Posts";
export default function Search(props) {
    const {posts, loading, error} = useAudio(props.match.params.id);

    if(loading){
        return <span className='text-center'>Loading</span>
    }
    if(error){
        return <span className='text-center'>error</span>
    }
    return(<Container>
        {posts.map((post, i) => {
        return <DisplayPost post={post} key={i} history={props.history}/>
        })}

    </Container>)

}
