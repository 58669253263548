import React, {useContext, useState, useEffect} from 'react'
import {fbContext} from "../Firebase"
import {useMountedRef} from "./useMountedRef";

export function useUid(uid){
    const mounted = useMountedRef()
    const fb = useContext(fbContext)
    const [url, setUrl] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()



    useEffect(() => {
        if(!uid)return;
        if(!mounted.current)return;
        fb.database.ref(uid).once('value').then(snapshot => {if(!mounted.current) throw new Error("Component Not Mounted"); return snapshot;})
            .then((snapshot) => setUrl({Photo: snapshot.val().Photo, DisplayName: snapshot.val().Username}))
            .then(() => setLoading(false))
            .catch((error) => {if(!mounted.current) return; setError(error)})
    }, [uid])
    return {url, loading, error}

}
