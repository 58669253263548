import React, {useContext, useState, useEffect} from 'react'
import {fbContext} from "../Firebase"
import {useMountedRef} from "./useMountedRef";

export function useDatabase(uid){
    const mounted = useMountedRef()
    const fb = useContext(fbContext)
    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()



    useEffect(() => {
        if(!uid)return;
        if(!mounted.current)return;
        fb.database.ref(`${uid}/audio`).once('value').then(snapshot => {if(!mounted.current) throw new Error("Component Not Mounted"); return snapshot;})
            .then((snapshot) => snapshot.forEach(snapshot=> {
                setPosts((posts) =>[...posts, snapshot.val()])
            }))
            .then(() => setLoading(false))
            .catch((error) => {if(!mounted.current) return; setError(error)})
    }, [uid])
    return {posts, loading, error}

}
