import React from 'react';
import Container from 'react-bootstrap/Container';
import {DisplayInspectPost, DisplayPost} from "./Posts";
import {useSingle} from "../Hooks/useSingle";
export default function InspectPost({currentPlayer, setPlayer, setPost, props}) {

    const {posts, loading, error} = useSingle(props.match.params.id);
    if(loading){
        return <span className="text-center">loading</span>
    }
    return(<Container>
        <DisplayInspectPost post={posts[0]}  currentPlayr={currentPlayer} setPlayer={setPlayer} setPost={setPost}/>

    </Container>)


}
