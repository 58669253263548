import React, {useEffect, useState, useRef} from 'react';
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import {BsPauseFill, BsPlayFill} from "react-icons/bs";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
export default function Player({file}){
    console.log(file)
    const [musicSource] = useState(new MusicSource())
    const [loading, setLoading] = useState(true);
    console.log(musicSource)
    useEffect(() => {
        musicSource.setBuffer(file.Url).then(r => setLoading(false))
    }, [file])
    if(loading){
        return <span className="text-center fixed-bottom">Loading</span>
    }
    return (

        <Container className="fixed-bottom justify-content-between">
            <h1>Playing: {file.Title}</h1>
            <PlayPauseHolder audioContext={musicSource.audioContext}/>
            <ControlsHolder musicSource={musicSource}/>
            <DurationHolder musicSource={musicSource}/>
        </Container>
    )

}
const ControlsHolder = ({musicSource}) => {
    const [showVolume, setVolume] = useState(false);

    return(
        <Form>
            <Row className="align-items-center d-flex flex-row">
            <Button onClick={() => setVolume((prev) => !prev)}>Volume</Button>
            {showVolume && <Form.Group>
                <Form.Control type="range" min="0" step='any' max="2"
                              onChange={(e) => musicSource.gainNode.gain.setValueAtTime(e.target.value, musicSource.audioContext.currentTime)}
                              custom/>
            </Form.Group>}
            </Row>
            <Form.Group>
                <Form.Label>Playback Rate</Form.Label>
                <Form.Control type="range" min=".50" step='any' max="1.5"
                              onChange={(e) => musicSource.source.playbackRate.linearRampToValueAtTime(e.target.value, musicSource.audioContext.currentTime)} custom/>
            </Form.Group>
        </Form>
    )

}


const DurationHolder = ({musicSource}) => {
    const [time, setTime] = useState(musicSource.audioContext.currentTime)
    useEffect(() => {
        if (musicSource.audioContext.state === "running") {
            const interval = setInterval(() => setTime((prev) => prev + 1), 1000)
            return () => clearInterval(interval)
        } else {
            console.log(musicSource.audioContext.state)
        }

    })
    return(<Form.Group>
        <Form.Label>Duration</Form.Label>
        <Form.Control type="range" min="0" step='any' max={musicSource.source.buffer.length} value={musicSource.audioContext.currentTime} onChange={(e) => musicSource.seek(e.target.value)}/>
    </Form.Group>)
}

const PlayPauseHolder = ({audioContext}) => {
    const [state, setState] = useState(audioContext.state)
    return( <>{state === "running" ?
        <Button variant="success" onClick={() => {audioContext.suspend(); setState('suspended')}}><BsPauseFill/></Button>:<Button variant="success" onClick={() => {audioContext.resume(); setState('running')}}><BsPlayFill/></Button>}</>)
}

class MusicSource{
    constructor(){
        this.loading = true;
        let AudioContext = window.AudioContext || window.webkitAudioContext;
        this.audioContext = new AudioContext();
        this.gainNode = this.audioContext.createGain();
        this.source = this.audioContext.createBufferSource();
        this.storedbuffer = null;
        this.source.connect(this.gainNode).connect(this.audioContext.destination)

    }

    async setBuffer(url) {
        if(this.source.buffer === null) {
            await fetch(url).then((res) => {
                console.log(res)
                return res.arrayBuffer()
            }).then((arrayBuffer) => {
                return this.audioContext.decodeAudioData(arrayBuffer)
            }).then((buffer) => this.source.buffer = buffer).then((buffer) => this.storedbuffer = buffer).then(() => this.source.start(0, 0))
                .then(() => this.loading = false)
        }else{
            this.source.stop()
            this.source = new AudioBufferSourceNode(this.audioContext)
            this.source.connect(this.gainNode).connect(this.audioContext.destination)
            await fetch(url).then((res) => {
                console.log(res)
                return res.arrayBuffer()
            }).then((arrayBuffer) => {
                return this.audioContext.decodeAudioData(arrayBuffer)
            }).then((buffer) => this.source.buffer = buffer).then((buffer) => this.storedbuffer = buffer).then(() => this.source.start(0, 0))
                .then(() => this.loading = false)
        }
    }
    seek(time){
        this.source.stop()
        this.source = new AudioBufferSourceNode(this.audioContext)
        this.source.buffer = this.storedbuffer;
        this.source.connect(this.gainNode).connect(this.audioContext.destination)
        this.source.start(0, time)
    }
    nextSong(){
        this.source.stop()
        this.source = new AudioBufferSourceNode(this.audioContext)
        this.setBuffer()

    }
}
