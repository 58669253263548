import React, {useContext, useState, useEffect} from 'react'
import {fbContext} from "../Firebase"
import {useMountedRef} from "./useMountedRef";

export function useSingle(key){
    const mounted = useMountedRef()
    const fb = useContext(fbContext)
    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()



    useEffect(() => {
        if(!key)return;
        if(!mounted.current)return;
        fb.database.ref(`audio/${key}`).once('value').then(snapshot => {if(!mounted.current) throw new Error("Component Not Mounted"); return snapshot;})
            .then((snapshot) => setPosts((posts) =>[...posts, snapshot.val()]))
            .then(() => setLoading(false))
            .catch((error) => {if(!mounted.current) return; setError(error)})
    }, [key])
    return {posts, loading, error}

}
